import React from "react";
import nillion from "../images/nillion.svg";
import math from "../images/99math.webp";
import oblivious from "../images/oblivious.svg";
import dromos from "../images/Dromos-logo-png-1.png";
import sedicii from "../images/sedicii.svg";
import urbanfox from "../images/urbanfox.jpg";
import kipu from "../images/kipu-quantum.png"

const Portfolio = () => {
  return (
    <div name="portfolio" className=" h-auto w-full  ">
      <div className="flex flex-col md:w-full   md:flex-row p-12 gap-2 md:my-32 ">
        <div className="   bg-gray-900">
          <a className="cursor-pointer" href="https://sedicii.com/">
            <img className="p-6" src={sedicii} alt="sedicii logo" width={300} />
          </a>
        </div>
        <div className="   bg-slate-200">
          <a className="cursor-pointer" href="https://urbanfox.io/">
            <img
              className="p-6"
              src={urbanfox}
              alt="urbanfox logo"
              width={300}
            />
          </a>
        </div>

        <div className="   bg-blue-900">
          <a className="cursor-pointer" href="https://www.nillion.com/">
            <img
              className="p-6 "
              src={nillion}
              alt="nillion logo"
              width={300}
            />
          </a>
        </div>
        <div className="  bg-slate-100">
          <a className="cursor-pointer" href="https://www.oblivious.ai/">
            <img
              className="p-6"
              src={oblivious}
              alt="oblivious logo"
              width={300}
            />
          </a>
        </div>

        <div className="  bg-indigo-700">
          <a className="cursor-pointer" href="https://99math.com/">
            <img className="p-6 " src={math} alt="math logo" width={300} />
          </a>
        </div>
        <div className="bg-gray-100">
          <a
            className="cursor-pointer"
            href="https://www.dromos.network/"
          >
            <img
              className="p-6"
              src={dromos}
              alt="dromos logo"
              width={300}
            />
          </a>
        </div>
        <div className="bg-black">
          <a
            className="cursor-pointer"
            href="https://kipu-quantum.com/"
          >
            <img
              className="p-6"
              src={kipu}
              alt="kipu-quantum logo"
              width={300}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
