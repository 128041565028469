import React from "react";

import { FaEnvelope, FaLinkedin, FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <div className="p-1 h-full bg-slate-900 text-blue-200 text-light lg:w-full text-left lg:pl-[18%]">
      <div className="mt-[8%]">
        <p className="text-xl  ">Rinconada Capital Club</p>
      </div>
      <div className="flex pl-1 mt-4 text-gray-200">
        <a className="contact" href="mailto:jkenevey@gmail.com">
          <FaEnvelope className="w-5 text-3xl mr-4 hover:text-blue-400" />
        </a>
        <a className="contact" href="https://www.linkedin.com/in/jkenevey/">
          <FaLinkedin className="w-5 text-3xl mr-4 hover:text-blue-400" />
        </a>
        <a className="contact" href=" https://twitter.com/Kenevey">
          <FaTwitter className="w-5 text-3xl mr-4 hover:text-blue-400" />
        </a>
      </div>

      <div className=" mt-4 border-t-2 border-white border-opacity-50 pt-12 md:w-[85%] pb-10 ">
        &copy;{new Date().getFullYear()} Rinconada Capital Club - All Rights
        Reserved
      </div>
    </div>
  );
}

export default Footer;
