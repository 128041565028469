import React from "react";
import bridge from "./rinconadabridge.jpg";
const Hero = () => {
  return (
    <div
      name="home"
      className="w-full  h-96 md:h-screen overflow-hidden relative"
    >
      <img
        className="absolute inset-0 w-full h-full object-cover"
        src={bridge}
        alt="bridge"
      />
      <div className="absolute inset-0 bg-gray-50 bg-opacity-80"></div>
      <div className="flex h-full mt-10 md:mt-40 md:ml-12 justify-center md:justify-end relative">
        <h1 className="w-3/4 md:w-[70%] lg:w-[60%] font-public font-medium   md:text-6xl sm:2xl text-3xl   py-2 text-sky-900">
          Investing in early stage deep tech companies that are founded in
          Ireland and Europe.
        </h1>
      </div>
    </div>
  );
};

export default Hero;
