import React from "react";
import halo from "./halo.png";
import { Link, animateScroll as scroll } from "react-scroll";
import { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  const [nav, setNav] = useState(true);
  const handleClose = () => setNav(!nav);
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex  justify-between items-center h-31  md:h-24 max-w-full mx-auto px-6 text-sky-800 bg-gray-100">
      <div className="p-4">
        <h1 className="md:text-3xl text-2xl font-semibold rounded-lg font-public">
          <span className=" text-sky-900">RINCONADA </span>

          <span className="font-light text-green-800 md:text-2xl text-xl">
            Capital Club
          </span>
        </h1>

        <p className="pt-2 md:text-sm text-xs text-center font-public   ">
          The farther back you can look, the farther forward you are likely to
          see.
        </p>
      </div>
      <ul className="hidden md:flex ">
        <li className="p-6 cursor-pointer">
          <Link to="/" smooth={true} offset={50} duration={500}>
            Home
          </Link>
        </li>
        <li className="p-6 cursor-pointer">
          <Link to="about" smooth={true} offset={0} duration={500}>
            About
          </Link>
        </li>
        <li className="p-6 cursor-pointer">
          <Link to="portfolio" smooth={true} offset={0} duration={500}>
            Portfolio
          </Link>
        </li>
      </ul>
      <div onClick={handleNav} className="block md:hidden">
        {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <div
        className={
          !nav
            ? "fixed left-0 top-0 w-[85%]  bg-slate-900 text-blue-200 ease-in-out duration-500 z-10 h-96 "
            : "fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-light m-4">
          Rinconada Capital Club
        </h1>
        <ul className=" uppercase">
          <li className="p-8">
            <Link
              onClick={handleClose}
              to="/"
              smooth={true}
              offset={50}
              duration={500}
            >
              Home
            </Link>
          </li>
          <li className="p-8">
            <Link
              onClick={handleClose}
              to="about"
              smooth={true}
              offset={50}
              duration={500}
            >
              About
            </Link>
          </li>
          <li className="p-8">
            <Link
              onClick={handleClose}
              to="portfolio"
              smooth={true}
              offset={50}
              duration={500}
            >
              Portfolio
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
